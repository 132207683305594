import { useState } from 'react';
import { Button } from '@mui/material';
import UnifiedShareDialog from './UnifiedShareDialog';

function ShareButton({
    languageData: previewLanguageData,
    examId,
    variant = 'contained',
    icon = null,
    color = 'primary',
    fromLMS = false, // Default determines which dialog appears first
}) {
    const [openDialog, setOpenDialog] = useState(false);
    const [lmsData, setLmsData] = useState(null); // Store fetched LMS data

    const handleShareClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Button
                variant={variant}
                color={color}
                onClick={handleShareClick}
                endIcon={icon}
            >
                {previewLanguageData?.share_exam ?? 'Share'}
            </Button>

            {/* Unified Dialog opens on click */}
            <UnifiedShareDialog
                languageData={previewLanguageData}
                open={openDialog}
                onClose={handleCloseDialog}
                examId={examId}
                lmsData={lmsData}
                setLmsData={setLmsData}
                fromLMS={fromLMS} // Determines default dialog type
            />
        </>
    );
}

export default ShareButton;
