import React, { useMemo } from 'react';
import { Grid, List, ListItemText, Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLanguage } from '../../contexts/languageContext';
import { links } from '../../utils';

const SubjectList = ({ subjects = [], onSubjectSelect, exams = [] }) => {
    const subjectColors = ['#D37676', '#A5D6A7', '#81D4FA', '#FFAB91', '#496989', '#15E1A5', '#FFB74D', '#B39DDB', '#50727B', '#747264', '#503C3C'];
    const { language, isRTL, languageData } = useLanguage();

    const handleFocusTransition = (e) => {
        e.currentTarget.style.transform = 'scale(1.07)';
        e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.1)';
    }
    const handleBlurTransition = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = 'none';
    }

    const sortedSubjects = useMemo(() => {
        if (!Array.isArray(subjects)) return [];
        return [...subjects].sort((a, b) => {
            const aLabel = a.labels?.[language] || a.labels?.['en'] || 'Unknown';
            const bLabel = b.labels?.[language] || b.labels?.['en'] || 'Unknown';

            if (aLabel === "Other") return 1;
            if (bLabel === "Other") return -1;
            return aLabel.localeCompare(bLabel);
        });
    }, [subjects, language]);

    const countExamsForSubject = (subjectLabel) => {
        if (!Array.isArray(exams)) return 0;
        return exams.filter(item => item.subject.labels[language] === subjectLabel).length;
    };

    return (
        <List>
            <Grid container spacing={2}>
                {!sortedSubjects.length ? <CircularProgress sx={{ margin: '5rem auto' }} />
                    : sortedSubjects.map((subject, index) => {
                        const subjectLabel = subject.labels?.[language] || subject.labels?.['en'] || 'Unknown';

                        return (
                            <Grid key={subject._id || index} item xs={12} sm={6} md={4}>
                                <Card
                                    elevation={0}
                                    style={{
                                        margin: '8px',
                                        borderRadius: '12px',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                    }}
                                    tabIndex={0}
                                    onMouseEnter={handleFocusTransition}
                                    onFocus={handleFocusTransition}
                                    onMouseLeave={handleBlurTransition}
                                    onBlur={handleBlurTransition}
                                    onClick={() => onSubjectSelect(subject)}
                                    onKeyDown={(e) => e.key === 'Enter' ? onSubjectSelect(subject) : null}
                                >
                                    <CardContent
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            flexGrow: 1,
                                            width: "100%",
                                            padding: 0
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            // bgcolor={alpha(subjectColors[index % subjectColors.length], 0.2)}
                                            width="100%"
                                            minHeight="100px"
                                            maxHeight="200px"
                                            mb={2}
                                        >
                                            <img
                                                src={subject?.cover ?? links.LOGO}
                                                alt={subjectLabel}
                                                style={{ width: '100%', height: '100%' }}
                                            />
                                        </Box>
                                        <ListItemText
                                            sx={{ direction: isRTL ? 'rtl' : 'ltr' }}
                                            primaryTypographyProps={{ variant: 'h5', fontWeight: 500 }}
                                            secondaryTypographyProps={{ fontWeight: 600 }}
                                            primary={subjectLabel}
                                            secondary={`${languageData?.hub?.subtitle}${countExamsForSubject(subject.labels[language])}`}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
            </Grid>
        </List>
    );
};

export default SubjectList;
