import React, { useMemo } from 'react';
import { List, ListItem, ListItemIcon, Avatar, Tooltip, Badge } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import GradingIcon from '@mui/icons-material/Grading';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SaveToHubButton from '../../components/SaveToHubButton/SaveToHubButton';
import MergeButton from '../../components/Merge/MergeButton';
import ROLES from '../../utils/roles';
import { colors } from '../../utils/customTheme';
import useHistory from '../../hooks/useHistory';

const PreviewMenu = ({ isEditing, isRTL, examId, toggleEditingMode, handleCancelEdit, languageData: previewLanguageData, onMerge, roles, subject }) => {
    const { history } = useHistory();
    const responses = useMemo(() => history?.find(exam => exam._id === examId)?.responses_count, [history]);
    const navigate = useNavigate();

    const handleGoToReview = () => {
        navigate(`/review?id=${examId}`);
    }

    return (
        <div style={{
            position: 'fixed',
            maxHeight: 'fit-content',
            top: 200,
            right: -10,
            bottom: 0,
            backgroundColor: 'white',
            width: 'auto',
            borderLeft: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
            borderTop: '1px solid #ccc',
            borderRadius: 10,
        }}>
            <List>
                {/* Edit or Save Button */}
                <ListItem button onClick={toggleEditingMode} sx={{ justifyContent: 'flex-end' }}>
                    <Tooltip title={!isEditing ? previewLanguageData.tooltips.edit : previewLanguageData.save_label} placement="left">
                        <Avatar sx={{ bgcolor: isEditing ? colors.success : colors.main, color: 'white' }}>
                            {isEditing ? <DoneIcon sx={{ bgcolor: colors.success, color: 'white', borderRadius: '50%' }} /> : <EditIcon sx={{ color: 'white' }} />}
                        </Avatar>
                    </Tooltip>
                </ListItem>

                {/* Cancel Edit Button (only visible when editing) */}
                {isEditing && (
                    <ListItem button onClick={handleCancelEdit} sx={{ justifyContent: 'flex-end' }}>
                        <Tooltip title={previewLanguageData.cancel_label} placement="left">
                            <Avatar sx={{ bgcolor: 'red', color: 'white', borderRadius: '50%' }}>
                                <CloseIcon sx={{ color: 'white' }} />
                            </Avatar>
                        </Tooltip>
                    </ListItem>
                )}

                {!isEditing && (
                    <>
                        {/* Merge Button */}
                        <ListItem>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <Tooltip title={previewLanguageData.tooltips.merge} placement="left">
                                    <MergeButton isRTL={isRTL} languageData={previewLanguageData} onMerge={onMerge} examId={examId} />
                                </Tooltip>
                            </ListItemIcon>
                        </ListItem>

                        {/* Review Button */}
                        <ListItem button onClick={handleGoToReview} sx={{ justifyContent: 'flex-end' }}>
                            <Tooltip title={previewLanguageData.tooltips.review} placement="left">
                                <Badge badgeContent={responses} color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} overlap="circular">
                                    <Avatar sx={{ bgcolor: colors.main, color: 'white' }}>
                                        <GradingIcon sx={{ color: 'white' }} />
                                    </Avatar>
                                </Badge>
                            </Tooltip>
                        </ListItem>

                        {/* Add to Hub Button (only visible to teachers) */}
                        {roles?.includes(ROLES.TEACHER.label) && (
                            <ListItem>
                                <Tooltip title={previewLanguageData.tooltips.add_to_hub} placement="left">
                                    <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                        <SaveToHubButton examId={examId} subject={subject} forceMobileStyle />
                                    </ListItemIcon>
                                </Tooltip>
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </div>
    );
}

export default PreviewMenu;
