import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Switch, FormControlLabel, Box } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { useLanguage } from '../../contexts/languageContext';
import { languageOptions, levelOptionsList } from '../../utils/examOptions';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const FilterKeys = Object.freeze({
    SEARCH: 'search',
    DIFFICULTY: 'difficulty',
    LANGUAGE: 'language',
    SHOW_EXAMS_WITH_RESPONSES: 'showExamsWithResponses',
    // ACTIVE_EXAMS: 'showExamsWithResponses' // todo: add in future
});

const Filters = ({ filters = Object.values(FilterKeys), onFilterChange }) => {
    const { languageData, isRTL } = useLanguage();
    const [filterValues, setFilterValues] = React.useState({
        search: '',
        difficulty: '',
        language: '',
        showExamsWithResponses: false
    });
    const debouncedFilterChange = useDebounce(onFilterChange, 300);
    const menuItemStyle = React.useMemo(() => ({ justifyContent: isRTL ? 'flex-end' : 'flex-start' }), [isRTL]);

    const handleChange = (filter, value) => {
        const newFilters = { ...filterValues, [filter]: value };
        setFilterValues(newFilters);
        if (filter === FilterKeys.SEARCH)
            debouncedFilterChange(newFilters);
        else
            onFilterChange(newFilters);
    };

    return (
        <Box width={'-webkit-fill-available'} dir={isRTL ? 'rtl' : 'ltr'}>
            <Grid container spacing={2} sx={{ margin: '1rem auto', width: '100%' }}>
                {filters.includes(FilterKeys.SEARCH) && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={languageData?.search_exam_label || 'Search Exam'}
                            placeholder={languageData?.search_exam_label || 'Search Exam'}
                            variant="outlined"
                            value={filterValues.search}
                            onInput={e => handleChange(FilterKeys.SEARCH, e.target.value)}
                            sx={{ width: '100%', backgroundColor: 'white' }}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                )}
                {filters.includes(FilterKeys.DIFFICULTY) && (
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="difficulty-select-label">{languageData?.difficulty_label || 'Difficulty'}</InputLabel>
                            <Select
                                labelId="difficulty-select-label"
                                onChange={e => handleChange(FilterKeys.DIFFICULTY, e.target.value)}
                                value={filterValues.difficulty}
                                label={languageData?.difficulty_label}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem value="" sx={menuItemStyle}>{languageData?.hub?.dialog?.all ?? 'All'}</MenuItem>
                                {(languageData?.levelOptions ?? levelOptionsList)?.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={menuItemStyle}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {filters.includes(FilterKeys.LANGUAGE) && (
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="language-select-label">{languageData?.language_label || 'Language'}</InputLabel>
                            <Select
                                labelId="language-select-label"
                                value={filterValues.language}
                                onChange={e => handleChange(FilterKeys.LANGUAGE, e.target.value)}
                                label={languageData?.language_label}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem value="" sx={menuItemStyle}>{languageData?.hub?.dialog?.all ?? 'All'}</MenuItem>
                                {(languageData?.languageOptions ?? languageOptions)?.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={menuItemStyle}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {filters.includes(FilterKeys.SHOW_EXAMS_WITH_RESPONSES) && (
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={filterValues.showExamsWithResponses}
                                    onChange={e => handleChange(FilterKeys.SHOW_EXAMS_WITH_RESPONSES, e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={languageData?.history?.show_with_responses || "Show exams with responses"}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default Filters;
