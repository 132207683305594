import { inputTypes } from "./configs";

export function getInputSource(source) {
    const specialSources = [inputTypes.url, inputTypes.math, inputTypes.unseen];
    return specialSources.includes(source) ? source : inputTypes.text;
}

export function handleRemix(examParams = {}, navigate) {

    const { prompt = '', focus = '', subject = '', questions, bloomsArray, difficulty = '', language = '', source = '', skills = [],
        example = '', use_indicator = true, limit_content = false } = examParams;

    const inputSource = getInputSource(source)

    const remixData = {
        prompt,
        difficulty,
        language,
        subject,
        questions: bloomsArray ?? questions ?? '',
        focus,
        skills,
        source,
        example,
        use_indicator,
        limit_content,
    };
    sessionStorage.setItem('remix', JSON.stringify(remixData));
    navigate(`/exam?input=${inputSource}`);
};