import React, { createContext, useContext, useEffect, useState } from 'react';
import { getSubjects, requestAddSubject, requestDeleteSubject, requestEditSubject } from '../requests/subjects';
import { useUser } from '../hooks/useUser';

/**
 * @typedef {Object} Subject
 * @property {string} _id - The unique identifier for the subject.
 * @property {{en: string, he: string}} labels - A dictionary of language labels for the subject.
 * @property {string} prompt - A prompt related to the subject.
 */

const SubjectsContext = createContext();

const useSubjects = () => {
    return useContext(SubjectsContext);
};

const SubjectsProvider = ({ children }) => {
    /**
     * @type {[Subject[], Function]} subjects - The array of subject objects.
     */
    const [subjects, setSubjects] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        if (user && !subjects) {
            fetchSubjects();
        }
    }, [user, subjects]);

    const fetchSubjects = async () => {
        return getSubjects().then(subjectsRes => {
            if (subjectsRes)
                setSubjects(subjectsRes);
            return subjectsRes;
        });
    }

    const addSubject = async (newSubject) => {
        return requestAddSubject(newSubject).then(addedSubject => {
            if (addedSubject) {
                setSubjects([...subjects, addedSubject]);
            }
        })
    };
    const editSubject = async (id, editingData) => {
        return requestEditSubject(id, editingData).then(() => {
            setSubjects(subjects.map(subject => {
                if (subject._id === id) {
                    return { ...subject, ...editingData };
                }
                return subject;
            }));
        });
    };

    const deleteSubject = async (id) => {
        return requestDeleteSubject(id).then(() => {
            setSubjects(subjects.filter(subject => subject._id !== id));
        })
    };

    return (
        <SubjectsContext.Provider value={{ subjects, fetchSubjects, addSubject, deleteSubject, editSubject }}>
            {children}
        </SubjectsContext.Provider>
    );
};

export { SubjectsProvider, SubjectsContext, useSubjects };
