import React, { useEffect, useState } from 'react';
import { Drawer, DialogContent, DialogTitle, Switch, TextField, FormControlLabel, FormHelperText, Box } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLanguage } from '../../contexts/languageContext';
import { isMobile } from '../../utils';
import ROLES from '../../utils/roles';
import { useUser } from '../../hooks/useUser';
import { useMemo } from 'react';
import { useDebounce } from '../../hooks/useDebounce';

const SettingsDrawer = ({ open, onClose, onSubmit, examSettings }) => {
    const { isRTL, languageData } = useLanguage();

    const currentDateTime = new Date();

    const defaultStartDate = new Date(currentDateTime.getTime() + 60 * 60 * 1000);
    const defaultEndDate = new Date(currentDateTime.getTime() + 3 * 60 * 60 * 1000);

    const [settings, setSettings] = useState(examSettings);
    const [startDate, setStartDate] = useState(examSettings?.start_time ? new Date(examSettings?.start_time) : defaultStartDate);
    const [endDate, setEndDate] = useState(examSettings?.end_time ? new Date(examSettings?.end_time) : defaultEndDate);
    const [showDateTimePicker, setShowDateTimePicker] = useState(!!examSettings?.start_time || !!examSettings?.end_time); // toggle based on existing values
    const [invalidDate, setInvalidDate] = useState(false);
    const { user } = useUser();
    const hasUnlimitedOrPremium = useMemo(() => {
        return ROLES.isPremiumRole(user?.roles)
    }, [user?.roles]);
    const debouncedSave = useDebounce((settingsToSave = settings) => onSubmit(settingsToSave), 1500);

    useEffect(() => {
        if (examSettings?.accept_responses === undefined || examSettings?.accept_responses === null) {
            setSettings({ ...examSettings, accept_responses: true });
        } else {
            setSettings(examSettings);
        }
    }, [examSettings]);

    useEffect(() => {
        // If name login is disabled, limit to one response must be disabled & false
        if (!settings?.disable_name_login) {
            const newSettings = { ...settings, limit_to_one_response: false };
            setSettings(newSettings);
            debouncedSave(newSettings);
        }
    }, [settings?.disable_name_login]);

    const handleToggleChange = (event) => {
        const newSettings = { ...settings, [event.target.name]: event.target.checked };
        setSettings(newSettings);
        debouncedSave(newSettings);
    };

    const handleDateTimePickerToggle = () => {
        setShowDateTimePicker(prevShowDateTimePicker => {
            const newShowDateTimePicker = !prevShowDateTimePicker;
            let newSettings = { ...settings, start_time: null, end_time: null };
            if (newShowDateTimePicker === true) {
                // When enabling DateTimePicker, set default times
                setStartDate(defaultStartDate);
                setEndDate(defaultEndDate);
                newSettings = { ...settings, start_time: defaultStartDate, end_time: defaultEndDate }
            }
            setSettings(newSettings);
            debouncedSave(newSettings);
            return newShowDateTimePicker;
        });
    };

    const handleInputChange = (event) => {
        const newSettings = { ...settings, [event.target.name]: event.target.value };
        setSettings(newSettings);
        debouncedSave(newSettings);
    };

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
        setSettings({ ...settings, start_time: newValue });

        // Ensure end date is after start date
        if (newValue >= endDate) {
            setInvalidDate(true);
        } else if (invalidDate) {
            setInvalidDate(false);
            debouncedSave({ ...settings, start_time: newValue });
        }
    };

    const handleEndDateChange = (newValue) => {
        const newEndtime = newValue.getTime();
        const startTime = startDate.getTime();
        if (newEndtime <= startTime) {
            // Prevent end date from being in the past or before start date);
            setInvalidDate(true);
        } else {
            if (invalidDate) {
                setInvalidDate(false);
            }
            setEndDate(newValue);

            setSettings({ ...settings, end_time: newValue });
            debouncedSave({ ...settings, end_time: newValue });
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            variant="temporary"
            PaperProps={{ sx: { width: isMobile ? '80vw' : '40vw', maxWidth: '100%' } }}
            dir={isRTL ? 'rtl' : 'ltr'}
        >
            <DialogTitle>{languageData?.settings_dialog?.title ?? 'Settings'}</DialogTitle>
            <DialogContent>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.accept_responses} onChange={handleToggleChange} name="accept_responses" />}
                        label={languageData?.settings_dialog?.accept_responses?.label ?? 'Accept Responses'}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.accept_responses.tooltip}</SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!showDateTimePicker} onChange={handleDateTimePickerToggle} name="date_time_enabled" />}
                        label={languageData?.settings_dialog?.date_time_enabled?.label ?? 'Date & Time'}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.date_time_enabled?.tooltip}</SettingHelperText>
                </SwitchDiv>
                {showDateTimePicker && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <DateTimePicker
                                label={languageData?.settings_dialog?.start_time?.label ?? 'Starts at'}
                                value={startDate}
                                onChange={handleStartDateChange}
                                format={isRTL ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm'}
                                minDateTime={new Date()} // Disable past dates
                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" sx={{ mt: 3 }} />}
                            />
                            <DateTimePicker
                                label={languageData?.settings_dialog?.end_time?.label ?? 'Ends at'}
                                value={endDate}
                                onChange={handleEndDateChange}
                                format={isRTL ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm'}
                                minDateTime={startDate} // Disable past dates
                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" sx={{ mt: 3 }} />}
                            />
                            {invalidDate && (
                                <FormHelperText error sx={{ mt: 1 }}>
                                    {languageData?.settings_dialog?.invalid_end_time ?? "Exam's end time must be after the exam's start time"}
                                </FormHelperText>
                            )}
                        </Box>
                    </LocalizationProvider>
                )}
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.identification_required} onChange={handleToggleChange} name="identification_required" />}
                        label={languageData?.settings_dialog?.identification_required.label}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.identification_required.tooltip}</SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.is_supervised} onChange={handleToggleChange} name="is_supervised" />}
                        label={languageData?.settings_dialog?.supervision.label}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.supervision.tooltip}</SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.show_results} onChange={handleToggleChange} name="show_results" />}
                        label={languageData?.settings_dialog?.show_results.label}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.show_results.tooltip}</SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.math_keyboard} onChange={handleToggleChange} name="math_keyboard" />}
                        label={languageData?.settings_dialog?.math_keyboard?.label ?? 'Enable Math Keyboard'}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>
                        {languageData?.settings_dialog?.math_keyboard?.tooltip ?? 'Enable a math-specific keyboard for input.'}
                    </SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.show_url} onChange={handleToggleChange} name="show_url" />}
                        label={languageData?.settings_dialog?.show_url?.label}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>
                        {languageData?.settings_dialog?.show_url?.tooltip}
                    </SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.shuffle_questions} onChange={handleToggleChange} name="shuffle_questions" />}
                        label={languageData?.settings_dialog?.shuffle_questions.label}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.shuffle_questions.tooltip}</SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.disable_name_login} onChange={handleToggleChange} name="disable_name_login" />}
                        label={languageData?.settings_dialog?.disable_name_login.label}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>{languageData?.settings_dialog?.disable_name_login.tooltip}</SettingHelperText>
                </SwitchDiv>
                <SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.limit_to_one_response} onChange={handleToggleChange} name="limit_to_one_response" disabled={!settings?.disable_name_login} />}
                        label={languageData?.settings_dialog?.limit_to_one_response?.label ?? 'Limit to 1 Response'}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>
                        {languageData?.settings_dialog?.limit_to_one_response?.tooltip ?? 'This ensures users can only respond once and enforces SSO.'}
                    </SettingHelperText>
                </SwitchDiv>
                {hasUnlimitedOrPremium && (<SwitchDiv>
                    <FormControlLabel
                        control={<Switch checked={!!settings?.recitation} onChange={handleToggleChange} name="recitation" />}
                        label={languageData?.settings_dialog?.enable_recitation?.label ?? 'Enable recitation'}
                        sx={{ margin: 0 }}
                    />
                    <SettingHelperText isRTL={isRTL}>
                        {languageData?.settings_dialog?.enable_recitation?.tooltip ?? 'If enabled, the examinee will be able to have the questions read aloud to them.'}
                    </SettingHelperText>
                </SwitchDiv>)}
                <TextField
                    label={languageData?.settings_dialog?.pass_mark.label ?? 'Pass Mark (%)'}
                    type="number"
                    name="pass_mark"
                    value={settings?.pass_mark}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    helperText={languageData?.settings_dialog?.pass_mark.helper_text}
                />
            </DialogContent>
        </Drawer>
    );
};

export default SettingsDrawer;

function SwitchDiv({ children }) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
        {children}
    </div>
}

function SettingHelperText({ children, isRTL }) {
    return <FormHelperText sx={{ ml: 1, overflowWrap: 'break-word', wordBreak: 'break-word', fontSize: '0.8rem', textAlign: isRTL ? 'right' : 'left' }}>{children}</FormHelperText>
}
