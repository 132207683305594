import React, { useState, useEffect, useRef } from 'react'
// MUI
import {
  IconButton,
  Grid,
  Typography,
  Paper,
  Divider,
  TextField,
  Toolbar,
  Alert,
  CircularProgress,
  Box,
  Tooltip,
  Chip,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PostAddIcon from '@mui/icons-material/PostAdd';
import TextFieldsIcon from '@mui/icons-material/TextFields';
// import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete'
// Components
import ExamSkeleton from './ExamSkeleton'
import EditableQuestionTypeManager from './EditableQuestionTypeManager'
import ParamsToChips from './ParamsToChips'
import Header from '../../components/Header'
import ExamDialog from '../../components/ExamDialog'
import ErrorMessage from '../../components/ErrorMessage'
import AddQuestionDialog from './AddQuestionDialog'
import PreviewHeader from './PreviewHeader';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';
import ModifyQuestionPopover from './ModifyQuestionPopover'
import PreviewButtons from './PreviewButtons'
import { GradeModal } from './GradeModal'
import Feedback from '../../components/Feedback'
// Utils
import { requestExamJson, saveExam } from '../../requests/exam'
import { requestModifyingQuestion, requestAddQuestion } from '../../requests/question'
import { questionsType, getQuestionTemplateByType } from '../../utils/examOptions'
import { generateId, isMobile } from '../../utils'
import { SnackbarTypes } from '../../utils/snackbarTypes';
// Hooks
import { useLocation } from 'react-router-dom'
import { HttpStatusCode } from 'axios'
import { languageToCodesMap, useLanguage } from '../../contexts/languageContext'
import { useUser } from '../../hooks/useUser'
import { useDebounce } from '../../hooks/useDebounce'
import useSnackBar from '../../hooks/useSnackBar';
import useHistory from '../../hooks/useHistory'
import PreviewMenu from './PreviewMenu'
import { paddingPageStyle } from '../../utils/customTheme';
import { useLobby } from '../../contexts/LobbyContext';
import Unseen from '../../components/Unseen'
import { deleteImage, uploadImage } from '../../requests/images'
import { UploadImageButton } from './UploadImageButton'
import { DeleteImageButton } from './DeleteImageButton'
import { Helmet } from 'react-helmet-async';
import QuestionComparisonDialog from './QuestionComparisonDialog';
import { inputTypes } from '../../utils/configs';
import ROLES from '../../utils/roles';
import { getBloomLevelColor, getBloomsLabel, getBloomsLevel } from '../../utils/bloom';
import FlickeringDot from './FlickeringDot';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const PreviewPage = () => {
  const location = useLocation()
  const { isRTL, languageData, ToggleLanguage } = useLanguage()
  const { user } = useUser()
  const { history, updateExamInHistory } = useHistory()
  const lobbyContext = useLobby();
  const { openSnackBar, } = useSnackBar();
  const debouncedSaveChanges = useDebounce(saveChanges, 5000);

  const [examJson, setExamJson] = useState(null)
  const [editableExamJson, setEditableExamJson] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const [openExamDialog, setOpenExamDialog] = useState(false)
  const [wasChanged, setWasChanged] = useState(false)

  const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false)
  const [newQuestionIndex, setNewQuestionIndex] = useState(null)
  const [modifyingQuestionIndex, setModifyingQuestionIndex] = useState(null);
  const [loadingUploadId, setLoadingUploadId] = useState(null)
  const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
  const deletePopoverPayload = useRef(null);

  const [isCompareDialogOpen, setIsCompareDialogOpen] = useState(false);
  const oldQuestionRef = useRef(null);
  const newQuestionRef = useRef(null);

  const [error, setError] = useState({ display: false, msg: '' })
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCancelChangesInModal = () => {
    setOpenModal(false)
    handleCancelEdit()
  }

  useEffect(() => {
    const fetchExamJson = async () => {
      const searchParams = new URLSearchParams(location.search)
      const examId = searchParams.get('id')
      const blank = searchParams.get('blank')
      const edit = searchParams.get('edit')

      if (!examId) {
        setError({
          display: true,
          msg: languageData.preview?.errors.no_preview,
        })
        return
      }

      try {
        const data = await requestExamJson(examId)
        const fixedExam = fixExamStructure(data)
        setExamJson(fixedExam)
        setEditableExamJson({ ...fixedExam })
        lobbyContext?.createdExam()

        if (fixedExam?.parameters?.language)
          ToggleLanguage(languageToCodesMap[fixedExam?.parameters?.language])
        if (blank && edit)
          setIsEditing(true)

      } catch (error) {
        setExamJson(null)
        setEditableExamJson(null)
        setError({
          display: true,
          msg: languageData.preview?.errors?.no_preview ?? "No preview available",
        })
      }
    }

    fetchExamJson()

    // Clean up the error message after the component unmounts
    return () => {
      setError({ display: false, msg: '' })
    }
  }, [])

  const insertNewQuestion = (newQuestion, grade) => {
    if (!newQuestion?._id) newQuestion._id = generateId()
    newQuestion.grade = grade ?? 0
    if (newQuestion.type === questionsType.nested) {
      newQuestion.nested_questions = handleAddNestedQuestionGrade(newQuestion, grade)
    }

    // Insert the new question at the specified index
    setEditableExamJson(prevExam => {
      let updatedQuestions = [...prevExam.questions];
      updatedQuestions.splice(newQuestionIndex, 0, newQuestion);
      return { ...prevExam, questions: updatedQuestions };
    });
    setWasChanged(true);
    setNewQuestionIndex(null)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    setEditableExamJson((prevExam) => {
      const updatedQuestions = [...prevExam.questions];
      const [removed] = updatedQuestions.splice(source.index, 1);
      updatedQuestions.splice(destination.index, 0, removed);

      const newExam = { ...prevExam, questions: updatedQuestions };
      saveChanges(newExam);
      return newExam;
    });

    setWasChanged(true);
  };

  const addNewQuestionWithAI = (newQuestion, grade) => {
    return requestAddQuestion(newQuestion, editableExamJson, examJson?.parameters?.language).then(newQuestion => {
      if (!newQuestion) {
        openSnackBar(SnackbarTypes.ERROR.field);
        return
      }
      insertNewQuestion(newQuestion, grade);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setNewQuestionIndex(null)
    })
  }

  /**
   * @param {{type: string, grade: number}} question 
   */
  const handleAddQuestion = async (question) => {
    const { type: questionType, grade } = question
    if (!questionType) {
      setNewQuestionIndex(null)
      return
    }
    // get template of a new question
    const newQuestion = getQuestionTemplateByType(questionType, languageData?.preview?.blank_question, examJson?.parameters?.use_indicator)
    insertNewQuestion(newQuestion, grade);
    // addNewQuestionWithAI(newQuestion);
  };

  const handleAddNestedQuestionGrade = (question, grade) => {
    const { nested_questions } = question
    const newNestedGrade = Math.round(grade / nested_questions.length)
    return nested_questions.map((nq, index) => {
      if (index === nested_questions.length - 1) {
        // the last nested question rounds the grade to the nearest integer
        return { ...nq, grade: grade - (newNestedGrade * (nested_questions.length - 1)) }
      }
      return { ...nq, grade: newNestedGrade }
    })
  }

  const handleDeleteQuestion = (questionId) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.filter(question => question._id !== questionId);
      return { ...prevExam, questions: updatedQuestions };
    });
    setWasChanged(true);
  }
  const handleClickDeleteQuestion = (currentTarget, questionId) => {
    setDeletePopoverAnchorEl(currentTarget);
    deletePopoverPayload.current = { questionId, onDelete: () => handleDeleteQuestion(questionId) };
  }

  const handleSubmitModalWithGrade = async () => {
    await saveChanges(editableExamJson)
    setOpenModal(false)
  }
  function getTotalGrade(questions) {
    let totalGrade = 0
    questions.forEach(question => {
      totalGrade += parseFloat(question.grade)
    })
    return parseFloat(totalGrade.toFixed(2))
  }
  async function handleSaveChangesFromEditPage() {
    let totalGrade = 0
    totalGrade = getTotalGrade(editableExamJson.questions)

    if (totalGrade !== 100) {
      handleOpenModal()
    }
    else {
      await saveChanges(editableExamJson)
    }
  }

  async function saveChanges(exam = editableExamJson) {
    if (!exam.title) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError({
        display: true,
        msg: languageData.preview?.errors.missing_title,
      })
      return Promise.reject(languageData.preview?.errors.missing_title)
    }
    if (hasEmptyFields(exam.questions)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError({
        display: true,
        msg: languageData.preview?.errors.missing_field,
      })
      return Promise.reject(languageData.preview?.errors.missing_field)
    }

    try {
      // We only save questions & title. (_id is mandatory for the db operation)
      const examToSave = { questions: exam.questions, title: exam.title, settings: exam?.settings || {} }
      // Validate and set text field
      if (exam.text && exam.text.trim() !== "") {
        examToSave.text = exam.text;
      } else {
        examToSave.text = null;
      }

      // Validate and set instructions field
      if (exam.instructions && exam.instructions.trim() !== "") {
        examToSave.instructions = exam.instructions;
      } else {
        examToSave.instructions = null;
      }

      const response = await saveExam(exam._id, examToSave)
      if (response.status === HttpStatusCode.Ok) {
        // todo: we might want to delete the setExamJson here, since its only saving in db
        setExamJson({ ...exam })
        setEditableExamJson({ ...exam })
        updateExamInHistory(exam)
        openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setError({
          display: true,
          msg: languageData.preview?.errors.try_again,
        })
      }
    } catch (e) {
      setError({
        display: true,
        msg: languageData.preview?.errors.no_save,
      })
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      console.error(e)
    }
  }

  const toggleEditingMode = async () => {
    try {
      if (isEditing) {
        if (wasChanged) await handleSaveChangesFromEditPage()
      }
      setIsEditing((prevState) => !prevState)
    } catch (e) {
      return
    }
  }

  const handleExamTitleChange = (e) => {
    setEditableExamJson((exam) => ({ ...exam, title: e.target.value }))
    setWasChanged(true)
  }

  const handleClosedQuestionChange = (index, newQuestion, newOptions, newCorrectAnswers) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (i === index) {
          return {
            ...questionItem,
            question: newQuestion,
            options: newOptions,
            correctAnswers: newCorrectAnswers,
          }
        }
        return questionItem
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }
  const handleNestedQuestionChange = (index, title, updateNestedQuestions, newGrade = -1) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((question, i) => {
        if (i === index) {
          return {
            ...question,
            question: title,
            nested_questions: updateNestedQuestions,
            ...(newGrade >= 0 ? { grade: newGrade } : {})
          }
        }
        return question
      })

      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }
  const handleOpenQuestionChange = (index, newQuestion) => {
    // delete grade from newQuestion if exists
    delete newQuestion.grade

    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((question, i) => {
        if (i === index) {
          return {
            ...question,
            ...newQuestion,
            question: newQuestion.title ?? newQuestion.question ?? question.question,
          }
        }
        return question
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }

  const handleCancelEdit = (e) => {
    setEditableExamJson(examJson)
    setIsEditing(false)
    setWasChanged(false)
  }

  const handleExportClick = (e) => {
    setOpenExamDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenExamDialog(false)
  }

  const handleSaveSettings = (newSettings) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            settings: {
              ...prevExam.settings,
              ...newSettings,
            },
          };

          saveChanges(updatedExam);
          return updatedExam;
        });

        setWasChanged(true);
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      throw error;
    }
  };

  const handleModifyQuestion = (index, instruction) => {
    setModifyingQuestionIndex(index);
    const question = editableExamJson.questions[index];
    const language = examJson?.parameters?.language;

    oldQuestionRef.current = question;

    requestModifyingQuestion(question, instruction, language).then((newQuestionResponse) => {
      if (!newQuestionResponse) {
        openSnackBar(SnackbarTypes.ERROR.field);
        handleCompareDialogClose()
        return;
      }
      const updatedNewQuestion = {
        ...newQuestionResponse,
        _id: question?._id || generateId(),
        grade: question?.grade,
      };

      newQuestionRef.current = updatedNewQuestion;
      setIsCompareDialogOpen(true); // Open the dialog

    }).catch(e => {
      openSnackBar(SnackbarTypes.ERROR.field);
      handleCompareDialogClose()
    })
  };

  const handleApproveDialog = (approved) => {
    if (!approved) return;

    setEditableExamJson((prevExam) => {
      const newQuestions = [...prevExam.questions];
      const questionIndex = newQuestions.findIndex(q => q._id === oldQuestionRef.current._id);

      if (questionIndex === -1) {
        openSnackBar(SnackbarTypes.ERROR.field);
        return prevExam;
      }

      // Update the question and handle state/save operations
      newQuestions[questionIndex] = newQuestionRef.current;

      const updatedExam = {
        ...prevExam,
        questions: newQuestions,
      };

      // Open success snackbar and save changes
      openSnackBar(SnackbarTypes.SUCCESS.field);
      debouncedSaveChanges(updatedExam);
      setIsCompareDialogOpen(false);

      return updatedExam;
    });

    setModifyingQuestionIndex(null);
  };


  const handleCompareDialogClose = () => {
    setIsCompareDialogOpen(false);
    oldQuestionRef.current = null;
    newQuestionRef.current = null;
    setModifyingQuestionIndex(null);
  };

  const handleGradeChanged = (index, newGrade) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (i === index) {
          return {
            ...questionItem,
            grade: newGrade,
          }
        }
        return questionItem
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }

  const getBloomLevel = (label, languageData) => {
    const matchingEntry = languageData.bloomsQuestions.find(entry => entry.value === label);
    return matchingEntry ? matchingEntry : undefined;
  };
  const handleUnseenTextChange = (text) => {
    setEditableExamJson((editableExamJson) => ({
      ...editableExamJson,
      text: text
    }))
    setWasChanged(true)
  }

  const handleInstructionsChange = (text) => {
    setEditableExamJson((editableExamJson) => ({
      ...editableExamJson,
      instructions: text
    }))
    setWasChanged(true)
  }
  /**
   * @param {string[]} selectedExamsIds - ids of the exams
   */
  const handleMergeExams = async (selectedExamsIds) => {
    try {
      let mergedQuestions = [];
      // Get all exams by given ids
      const selectedExams = history.filter(exam => selectedExamsIds.includes(exam._id));

      for (let i = 0; i < selectedExams.length; i++) {
        const exam = selectedExams[i];
        // Genertaing new ids for all questions, to create duplicates and avoid using the same id
        const newQuestions = exam.questions.map(question => ({ ...question, _id: generateId() }));
        mergedQuestions = mergedQuestions.concat(newQuestions);
      }

      // Update the editable exam JSON with the merged questions
      setEditableExamJson(prevExam => {
        const updatedExam = { ...prevExam, questions: [...prevExam.questions, ...mergedQuestions] };
        saveChanges(updatedExam)
        return updatedExam
      });
      setWasChanged(true)
    } catch (error) {
      console.error('Error merging exams:', error);
      throw error;
    }
  };

  const handleUploadImage = (id, event) => {
    let image = event.target.files[0]
    setLoadingUploadId(id)
    uploadImage(image).then((response) => {
      const imageData = response.image_data
      if (imageData.secure_url && imageData.public_id) {
        setEditableExamJson((prevExam) => {
          const updatedQuestions = prevExam.questions.map((questionItem, i) => {
            if (id === questionItem._id) {
              return {
                ...questionItem,
                image: { url: imageData.secure_url, meta: imageData },
              }
            }
            return questionItem
          })
          const newExam = { ...prevExam, questions: updatedQuestions }
          saveChanges(newExam)
          return newExam
        })
        setWasChanged(true)
      }
    }).catch(err => {
      openSnackBar(SnackbarTypes.ERROR.field);
    }).finally(() => setLoadingUploadId(null))
  }
  const handleDeleteImage = () => {
    setDeletePopoverAnchorEl(null);
    const { questionId } = deletePopoverPayload.current;
    const imageId = editableExamJson.questions.find(q => q._id === questionId)?.image?.meta?.public_id;

    if (!imageId) return;

    deleteImage(imageId).then(() => {
      setEditableExamJson((prevExam) => {
        const updatedQuestions = prevExam.questions.map((questionItem, i) => {
          if (questionId === questionItem._id) {
            return {
              ...questionItem,
              image: null,
            }
          }
          return questionItem
        })
        const newExam = { ...prevExam, questions: updatedQuestions }
        saveChanges(newExam)
        return newExam
      })
      setWasChanged(true)
    }).catch(err => {
      openSnackBar(SnackbarTypes.ERROR.field);
    })
  }
  const handleClickDeleteImage = (currentTarget, questionId) => {
    setDeletePopoverAnchorEl(currentTarget);
    deletePopoverPayload.current = { questionId, onDelete: handleDeleteImage };
  }
  const handleAddInstructions = (newInstructions) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            instructions: newInstructions,
          };

          return updatedExam;
        });

        setWasChanged(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });

      }
    } catch (error) {
      console.error('Error saving instructions:', error);
      throw error;
    }
  };

  const handleAddText = (newText) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            text: newText,
          };

          return updatedExam;
        });

        setWasChanged(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });

      }
    } catch (error) {
      console.error('Error saving new text:', error);
      throw error;
    }
  };

  if (!examJson || !languageData?.preview) {
    return (
      <>
        <Header />
        {error.display
          ? (<Grid item xs={12}>
            <ErrorMessage onHide={() => { }} isRTL={isRTL} message={error.msg} link='/history' buttonLabel={languageData?.preview?.view_exams ?? 'View My Exams'} />
          </Grid>)
          : <ExamSkeleton numberOfQuestions={5} />
        }
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`StudyWise | ${examJson?.title}`}</title>
        <meta name="description" content="Preview your upcoming exam with our AI-powered exam generator. Get an overview of the questions to prepare effectively and confidently." />
      </Helmet>
      <PreviewHeader examId={examJson?._id} handleSaveSettings={handleSaveSettings} examSettings={examJson?.settings} examParams={examJson?.parameters} />
      <div dir={isRTL ? 'rtl' : 'ltr'} >
        <Grid container gap={'20px'} style={{ position: 'relative', padding: isMobile ? 0 : 20, ...paddingPageStyle }} >
          <Grid item xs={12} >
            {isMobile ?
              <>
                <Toolbar sx={{ gap: '10px' }}>
                  <PreviewButtons
                    isEditing={isEditing}
                    isRTL={isRTL}
                    examId={examJson?._id}
                    toggleEditingMode={toggleEditingMode}
                    handleCancelEdit={handleCancelEdit}
                    languageData={languageData}
                    onMerge={handleMergeExams}
                    roles={user?.roles}
                    subject={examJson?.parameters?.subject?.length ? examJson?.parameters?.subject[0] : null}
                  />
                </Toolbar>
                <Divider />
              </>
              :
              <PreviewMenu
                isEditing={isEditing}
                isRTL={isRTL}
                examId={examJson?._id}
                toggleEditingMode={toggleEditingMode}
                handleCancelEdit={handleCancelEdit}
                handleSaveSettings={handleSaveSettings}
                languageData={languageData?.preview}
                onMerge={handleMergeExams}
                roles={user?.roles}
                examSettings={examJson?.settings}
                subject={examJson?.parameters?.subject?.length ? examJson?.parameters?.subject[0] : null}
              />
            }
          </Grid>
          <Grid item xs={12} style={{ padding: '0px 20px', marginBottom: '30px' }}>
            <Paper elevation={0} style={{ padding: isMobile ? 0 : `0 50px 0 50px`, backgroundColor: 'transparent' }}>
              {isEditing ? (
                <TextField
                  label={languageData?.preview?.exam_title}
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  value={editableExamJson.title}
                  onChange={handleExamTitleChange}
                  error={!editableExamJson.title}
                />
              ) : (
                <div>
                  <Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                    {editableExamJson.title}
                    <FlickeringDot isSuccess={examJson?.settings?.accept_responses} />
                  </Typography>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                <div>
                  <Typography variant='body1' gutterBottom>
                    {languageData?.preview.total_questions + '  ' + editableExamJson.questions.length}
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    {languageData.preview?.total_grade + '  ' + getTotalGrade(editableExamJson.questions)}
                  </Typography>
                </div>
                {!isEditing && (
                  <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Tooltip title={languageData.preview.tooltips.export_exam} placement="bottom">
                      <Chip variant="outlined" onClick={handleExportClick} label={languageData.preview?.tooltips?.export_exam}></Chip>
                    </Tooltip>
                  </div>
                )}
              </div>

              <Divider style={{ marginBottom: '20px' }} />
              {editableExamJson?.instructions && (
                isEditing ? (
                  <TextField
                    placeholder="Enter instructions here"
                    value={editableExamJson.instructions}
                    onChange={e => handleInstructionsChange(e.target.value)}
                    fullWidth
                    error={!editableExamJson.instructions}
                    multiline
                    variant="outlined"
                    margin="normal"
                  />
                ) : (
                  <Grid mb={"20px"}>
                    <Unseen text={editableExamJson.instructions} />
                  </Grid>
                )
              )}
              {/* Url source - link */}
              {examJson?.parameters?.source === inputTypes.url && examJson?.parameters?.prompt &&
                <Grid mb={"20px"}>
                  <Unseen link={examJson.parameters.prompt} />
                </Grid>
              }
              {editableExamJson?.text && (
                isEditing ? <TextField
                  label={languageData.question_title}
                  value={editableExamJson.text}
                  onChange={e => handleUnseenTextChange(e.target.value)}
                  fullWidth
                  error={!editableExamJson?.text}
                  multiline
                  variant="outlined"
                  margin="normal"
                /> :
                  <Grid mb={"20px"}>
                    <Unseen text={editableExamJson?.text} />
                  </Grid>
              )}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="questions">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {editableExamJson.questions.length === 0 && (
                        <IconButton
                          onClick={() => {
                            setOpenAddQuestionDialog(true)
                            setNewQuestionIndex(0)
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      )}

                      {editableExamJson.questions.map((question, index) => {
                        const imageInputId = `question-image-input-${index}`;
                        return (
                          <Draggable
                            key={question._id}
                            draggableId={question._id}
                            index={index}
                            isDragDisabled={isEditing}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div style={{ display: 'flex', flexDirection: 'column', opacity: snapshot.isDragging ? 0.7 : 1 }}>
                                  {isEditing && <InlineActionsBar
                                    languageData={languageData}
                                    setOpenAddQuestionDialog={setOpenAddQuestionDialog}
                                    setNewQuestionIndex={setNewQuestionIndex}
                                    index={index}
                                    editableExamJson={editableExamJson}
                                    handleAddInstructions={handleAddInstructions}
                                    handleAddText={handleAddText}
                                  />}
                                  <Paper style={{
                                    padding: '20px 30px',
                                    marginBottom: '20px',
                                    background: snapshot.isDragging ? '#f5f5f5' : 'white'
                                  }}>
                                    {(index === modifyingQuestionIndex || index === newQuestionIndex) ? (
                                      <ExamSkeleton />
                                    ) : (
                                      <>
                                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginBottom: '16px' }}>
                                          <div style={{ position: 'absolute', top: 0, ...(isRTL ? { left: 0 } : { right: 0 }), opacity: 0.7, display: 'flex', alignItems: 'start' }}>
                                            {!isEditing && examJson && examJson.parameters && <ModifyQuestionPopover onSelect={(option) => handleModifyQuestion(index, option)} />}
                                            {question?.image?.url ?
                                              <DeleteImageButton questionId={question._id} handleClickDeleteImage={handleClickDeleteImage} />
                                              : <UploadImageButton questionId={question?._id} imageInputId={imageInputId} handleUploadImage={handleUploadImage} />
                                            }
                                          </div>
                                          <Typography variant={"h5"} component="div" style={{ display: 'flex', alignItems: 'center' }}>
                                            {!isMobile && <div {...provided.dragHandleProps} style={{ cursor: 'grab', display: 'flex', alignItems: 'center' }}>
                                              <IconButton>
                                                <DragIndicatorIcon />
                                              </IconButton>
                                            </div>}
                                            {languageData.preview?.question + ' ' + (index + 1) + ' - ' + question.grade + ' ' + languageData.preview?.points_label}
                                            {(!isMobile && question?.bloom_level) && <Tooltip arrow title="Bloom's Taxonomy Level">
                                              <Chip
                                                label={languageData?.bloom_level_map[question?.bloom_level]}
                                                variant="outlined"
                                                color={getBloomLevelColor(getBloomsLevel(question?.bloom_level ?? null))}
                                                sx={{ fontWeight: 'bold', mx: isMobile ? 0 : 2, ...(isRTL ? { ml: 2 } : { mr: 2 }) }}
                                              />
                                            </Tooltip>}
                                          </Typography>
                                          {isEditing &&
                                            <IconButton onClick={e => handleClickDeleteQuestion(e.currentTarget, question._id)}>
                                              <DeleteIcon color="error" />
                                            </IconButton>}
                                        </div>
                                        <Grid container spacing={2}>
                                          {(loadingUploadId || question?.image?.url) &&
                                            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', overflow: 'hidden' }}>
                                              {loadingUploadId === question?._id
                                                ? <CircularProgress size={42} />
                                                : (question?.image?.url ? <img src={question.image.url} alt="" style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} /> : null)
                                              }
                                            </Grid>
                                          }
                                          <Grid item xs={12} md={12} lg={12}>
                                            <EditableQuestionTypeManager
                                              type={question.type}
                                              allQuestionsProps={{
                                                languageData: languageData.preview,
                                                questionIndex: index,
                                                title: question.question,
                                                grade: question.grade,
                                                isEditing: isEditing,
                                                onGradeChange: handleGradeChanged,
                                              }}
                                              nestedProps={{
                                                explanation: question.explanation,
                                                nested: question?.nested_questions,
                                                parentIndex: index + 1,
                                                onChange: handleNestedQuestionChange,
                                              }}
                                              openQuestionProps={{
                                                explanation: question?.explanation,
                                                indicator: question?.indicator,
                                                source: question?.source_link,
                                                onChange: handleOpenQuestionChange,
                                              }}
                                              closeQuestionProps={{
                                                options: question.options,
                                                correctAnswer: question.correctAnswers?.[0],
                                                onChange: handleClosedQuestionChange,
                                              }}
                                              GraphQuestionProps={{
                                                explanation: question.explanation,
                                                functions: question.functions,
                                                onChange: handleOpenQuestionChange,
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                  </Paper>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {isEditing && editableExamJson.questions.length > 0 && <InlineActionsBar
                        languageData={languageData}
                        setOpenAddQuestionDialog={setOpenAddQuestionDialog}
                        setNewQuestionIndex={setNewQuestionIndex}
                        index={editableExamJson.questions.length}
                        editableExamJson={editableExamJson}
                        handleAddInstructions={handleAddInstructions}
                        handleAddText={handleAddText}
                      />}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          </Grid>

          <AddQuestionDialog
            open={openAddQuestionDialog}
            onClose={() => setOpenAddQuestionDialog(false)}
            onAddQuestion={handleAddQuestion}
          />
          <ExamDialog
            open={openExamDialog}
            examJson={examJson}
            onClose={handleCloseDialog}
            id={examJson._id}
          />

        </Grid >
        <Alert severity="warning" >
          {languageData.preview?.ai_attention_alert}
        </Alert>
      </div >
      < GradeModal
        open={openModal}
        onClose={handleCancelChangesInModal}
        onSubmit={handleSubmitModalWithGrade}
        totalGrade={getTotalGrade(editableExamJson.questions)}
        languageData={languageData.preview}
      />
      {/* Delete confirmation for images */}
      <DeleteConfirmationPopver
        anchorEl={deletePopoverAnchorEl}
        onClose={() => setDeletePopoverAnchorEl(null)}
        onCancel={() => setDeletePopoverAnchorEl(null)}
        onDelete={() => { deletePopoverPayload.current?.onDelete(); setDeletePopoverAnchorEl(null) }}
      />
      {/* Compare questions after modify */}
      <QuestionComparisonDialog
        open={isCompareDialogOpen}
        onClose={handleCompareDialogClose}
        onApprove={handleApproveDialog}
        oldQuestion={oldQuestionRef.current}
        newQuestion={newQuestionRef.current}
      />
    </>
  )
}

export default PreviewPage

/**
 * Fixes the id and grade per question (if necessary)
 * @param {{ _id: string, title: string, questions: { _id: string, grade: float }[] }} examDocument
      */
function fixExamStructure(examDocument) {
  let saveIsNeeded = false
  const updatedExam = examDocument.questions.map((question) => {
    const newQuestion = { ...question }
    if (!question._id) {
      newQuestion._id = generateId()
      saveIsNeeded = true
    }
    if (!question.grade) {
      newQuestion.grade = Math.round(100 / examDocument.questions.length)
      saveIsNeeded = true
    }
    return newQuestion
  })
  examDocument.questions = updatedExam

  if (saveIsNeeded) {
    const examToSave = { questions: updatedExam }
    saveExam(examDocument._id, examToSave)
  }

  return examDocument
}

function hasEmptyFields(questions) {
  function containsInvalidValues(array) {
    return array.some(
      (value) => value === '' || value === undefined || value === null
    )
  }

  for (const item of questions) {
    if (item.type === questionsType.open || item.type === questionsType.graph) {
      if (!item.question || (!item.explanation && !item.indicator)) {
        return true // Found an item with empty fields
      }
    } else if (item.type === questionsType.nested) {
      //TODO add empty validation for nested question
      if (!item.question || !item.nested_questions) {
        return true // Found an item with empty fields
      }
    } else {
      if (
        !item.question ||
        !item.correctAnswers ||
        item.correctAnswers.length === 0 ||
        containsInvalidValues(item.correctAnswers) ||
        !item.options ||
        item.options.length === 0 ||
        containsInvalidValues(item.options)
      )
        return true // Found an item with empty fields
    }
  }
  return false // No items with empty fields found
}

function InlineActionsBar({ languageData, setOpenAddQuestionDialog, setNewQuestionIndex, index, editableExamJson, handleAddInstructions, handleAddText }) {
  return (
    <Box p={2} display="flex" justifyContent="center" alignItems="center" gap={1}>
      <Tooltip title={languageData?.preview?.tooltips?.add_question ?? "Add Question"} arrow>
        <IconButton onClick={() => {
          setOpenAddQuestionDialog(true);
          setNewQuestionIndex(index);
        }}>
          {editableExamJson && editableExamJson.parameters && <AddIcon />}
        </IconButton>
      </Tooltip>
      {!editableExamJson?.instructions && (
        <Tooltip title={languageData?.preview?.tooltips?.add_instructions ?? "Add Instructions"} arrow >
          <IconButton onClick={() => handleAddInstructions(languageData?.preview?.instructions ?? "New instruction text")}>
            <PostAddIcon />
          </IconButton>
        </Tooltip>
      )}
      {!editableExamJson?.text && (
        <Tooltip title={languageData?.preview?.tooltips?.add_text ?? "Add Text"} arrow >
          <IconButton onClick={() => handleAddText(languageData?.preview?.text ?? "Type your text here..")}>
            <TextFieldsIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}