import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { Typography } from '@mui/material';
import { getStudentResponse } from '../requests/quiz';
import { requestExamSettings } from '../requests/exam';

const LMSQuizRedirect = () => {
    const { isLoggedIn } = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const quizId = searchParams.get('id');
    const [authorized, setAuthorized] = React.useState(true);

    useEffect(() => {
        // Can add another logic for LMS student
        isLoggedIn().then(user => {
            if (!user || !quizId) {
                setAuthorized(false);
                return;
            }
            const moveToQuiz = () => {
                localStorage.setItem('studentData', JSON.stringify({ timestamp: new Date().getTime(), ...user }));
                window.open(`/quiz?id=${quizId}`, '_self');
            }
            return Promise.all([
                requestExamSettings(quizId),
                getStudentResponse(user?._id, quizId)
            ]).then(([examSettings, examResponse]) => {
                if (examResponse && examSettings?.show_results && examSettings?.limit_to_one_response) {
                    const responseId = examResponse?._id;

                    sessionStorage.setItem('examId', quizId);
                    sessionStorage.setItem('responseId', responseId);
                    window.open(`/results`, '_self');
                } else {
                    moveToQuiz();
                }
            }).catch(e => {
                console.error(e);
                moveToQuiz();
            })
        }).catch(e => {
            console.error(e);
            setAuthorized(false);
        });
    }, []);

    if (!authorized) {
        return (<div>
            <Typography variant='h6'>Not Authorized</Typography>
        </div>)
    }
    return (
        <div>
            <Typography variant='h6'>Redirecting...</Typography>
        </div>
    );
};

export default LMSQuizRedirect;