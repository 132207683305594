import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link as LinkIcon, School as SchoolIcon } from '@mui/icons-material';
import ShareDialog from './ShareDialog';
import LMSShareDialog from './LMSShareDialog';
import { useLanguage } from '../../contexts/languageContext';
import { colors } from '../../utils/customTheme';

const dialogTypes = {
  SHARE: 'share',
  LMS: 'lms',
}

const UnifiedShareDialog = ({
  languageData,
  open,
  onClose,
  examId,
  lmsData,
  setLmsData,
  fromLMS = false,
}) => {
  const { isRTL } = useLanguage();
  const [activeDialog, setActiveDialog] = useState(fromLMS ? null : dialogTypes.SHARE);

  // Reset toggle when dialog opens
  useEffect(() => {
    if (open) {
      setActiveDialog(fromLMS ? null : dialogTypes.SHARE); // Prevent non-LMS users from seeing LMS dialog initially
    }
  }, [open, fromLMS]);

  const handleToggleChange = (event, newValue) => {
    if (newValue !== null) {
      setActiveDialog(newValue);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" dir={isRTL ? 'rtl' : 'ltr'}>
      <DialogTitle>
        {languageData?.share_exam ?? 'Share Exam'}
      </DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          value={activeDialog}
          exclusive
          onChange={handleToggleChange}
          aria-label="dialog selection"
          fullWidth
          color="primary"
          sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}
        >
          <ToggleButton
            value={dialogTypes.SHARE}
            sx={{
              textTransform: 'none',
              backgroundColor: colors.lightblue,
              color: colors.main,
              m: 1,
            }}
          >
            <LinkIcon sx={{ m: 1 }} />
            {languageData?.share_link ?? 'Share Link'}
          </ToggleButton>
          <ToggleButton
            value={dialogTypes.LMS}
            sx={{
              textTransform: 'none',
              backgroundColor: colors.lightblue,
              color: colors.main,
              m: 1,
            }}
          >
            <SchoolIcon sx={{ m: 1 }} />
            {languageData?.share_lms ?? 'LMS Share'}
          </ToggleButton>
        </ToggleButtonGroup>

        {activeDialog === dialogTypes.LMS && (
          <LMSShareDialog
            isRTL={isRTL}
            languageData={languageData?.lms_dialog}
            open={open}
            onClose={onClose}
            onConfirm={onClose}
            lmsData={lmsData}
            setLmsData={setLmsData} // Pass the setter to cache LMS data
            examId={examId}
          />
        )}
        {activeDialog === dialogTypes.SHARE && (
          <ShareDialog
            isRTL={isRTL}
            languageData={languageData?.share_dialog}
            open={open}
            onClose={onClose}
            examId={examId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UnifiedShareDialog;
