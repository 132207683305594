import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSearchParams } from 'react-router-dom'; // Import to access URL parameters
import { inputTypes } from '../utils/configs';

import { useLanguage } from '../contexts/languageContext';

// Placeholder text arrays
const placeholderTexts = [
  "Present Simple for EFL Learners",
  "Math Equations and Problem Solving",
  "Introduction to Photosynthesis",
  "Newton's Laws of Motion",
  "World War II: Causes and Consequences",
  "Geometry: Area and Perimeter",
  "Human Digestive System Overview",
  "Shakespeare’s 'Romeo and Juliet'",
  "Everyday Chemical Reactions",
  "Industrial Revolution and Modern Society",
  "Introduction to Genetics",
  "English Grammar: Past and Present Tenses",
  "Chemistry of Acids and Bases",
  "Fundamentals of Algebra",
  "Ecosystems and Biodiversity"
];

const mathPlaceholders = [
  "Quadratic Equations and Graphs",
  "Understanding Trigonometric Ratios",
  "Calculating Derivatives",
  "Statistics: Mean, Median, Mode",
  "Basic Probability Concepts",
  "Integration Techniques",
  "Limits and Continuity",
  "Geometric Transformations",
  "Solving Linear Systems",
  "Exploring Vectors and Matrices"
];

const urlPlaceholders = [
  "https://www.khanacademy.org",
  "https://www.coursera.org",
  "https://www.sciencedaily.com",
  "https://www.nature.com",
  "https://www.wikipedia.org",
  "https://www.britannica.com",
  "https://www.nasa.gov",
  "https://www.newscientist.com",
  "https://www.edx.org",
  "https://www.scientificamerican.com"
];

const hebrewPlaceholderTexts = [
  "present simple ללומדי אנגלית כשפה זרה",
  "משוואות במתמטיקה ופתרון בעיות",
  "מבוא לפוטוסינתזה",
  "חוקי התנועה של ניוטון",
  "מלחמת העולם השנייה: סיבות ותוצאות",
  "גיאומטריה: שטח והיקף",
  "סקירה של מערכת העיכול האנושית",
  "רומיאו ויוליה מאת שייקספיר",
  "תגובות כימיות יומיומיות",
  "המהפכה התעשייתית והחברה המודרנית",
  "מבוא לגנטיקה",
  "דקדוק באנגלית: זמנים עבר והווה",
  "כימיה של חומצות ובסיסים",
  "יסודות האלגברה",
  "מערכות אקולוגיות ומגוון ביולוגי"
];

export default function FullWidthTextField({ textFieldProps, value, onChange, limit, placeholder }) {
  const { languageData, isRTL } = useLanguage();
  const [searchParams] = useSearchParams();
  const inputType = searchParams.get("input"); // Retrieve input type from URL

  // Set placeholders based on input type and language direction
  const placeholders = useMemo(() => {
    if (placeholder)
      return [placeholder];
    if (inputType === inputTypes.math)
      return mathPlaceholders;
    else if (inputType === inputTypes.url)
      return urlPlaceholders;
    else
      return isRTL ? hebrewPlaceholderTexts : placeholderTexts;
  }, [inputType, isRTL]);

  const [displayedText, setDisplayedText] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(Math.floor(Math.random() * placeholders.length));
  const [isTyping, setIsTyping] = useState(true);
  const [userTyping, setUserTyping] = useState(false);

  useEffect(() => {
    let typingTimeout;
    const currentPlaceholder = placeholders[placeholderIndex];

    if (!userTyping) {
      if (isTyping) {
        if (displayedText.length < currentPlaceholder.length) {
          typingTimeout = setTimeout(() => {
            setDisplayedText((prev) => prev + currentPlaceholder[displayedText.length]);
          }, 100); // Adjust typing speed here
        } else {
          setIsTyping(false);
          typingTimeout = setTimeout(() => setIsTyping(true), 2000); // Pause duration after full text
        }
      } else {
        if (displayedText.length > 0) {
          typingTimeout = setTimeout(() => {
            setDisplayedText((prev) => prev.slice(0, -1));
          }, 50); // Adjust deletion speed here
        } else {
          setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
          setIsTyping(true);
        }
      }
    }

    return () => clearTimeout(typingTimeout);
  }, [displayedText, isTyping, placeholderIndex, placeholders, userTyping]);

  const handleChange = (event) => {
    const inputValue = event.target.value.trim();
    setUserTyping(inputValue !== "");
    onChange(event);
  };

  return (
    <Box
      sx={{
        minWidth: '300px',
        margin: 'auto',
        padding: '20px',
      }}
    >
      <TextField
        value={value}
        fullWidth
        label={languageData.text_input_placeholder}
        id="fullWidth"
        inputProps={{ maxLength: parseInt(limit) }}
        multiline
        autoFocus
        onChange={handleChange}
        placeholder={displayedText || " "}
        {...textFieldProps}
      />
    </Box>
  );
}
