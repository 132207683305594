import { Paper, Typography } from '@mui/material';
import React from 'react';
import { renderTextWithBreakLines } from '../utils';
import { extractYouTubeVideoId } from '../utils/functions';

function Unseen({ text, link = null }) {
    if (!text && !link) return null;

    const videoId = extractYouTubeVideoId(link);
    const isYouTube = Boolean(videoId);
    const isWebPreview = link && !isYouTube;

    return (
        <Paper style={{ padding: '20px 30px', marginBottom: '20px', borderRadius: '2px' }}>
            {text && (
                <Typography variant="h6">{renderTextWithBreakLines(text)}</Typography>
            )}

            {isYouTube && (
                <>
                    <a href={link} target="_blank" rel="noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}> {link} </a>
                    <IframeView src={`https://www.youtube.com/embed/${videoId}`} title="YouTube Video" />
                </>
            )}

            {isWebPreview && (
                <>
                    <a href={link} target="_blank" rel="noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}> {link} </a>
                    <IframeView src={link} title="Embedded Web Page" />
                </>
            )}

            {(link && !isYouTube && !isWebPreview) && (
                <>
                    <LinkView href={link} />
                    <Typography variant="body1" style={{ marginTop: '10px' }}>
                        {link}
                    </Typography>
                </>
            )}
        </Paper>
    );
}

const IframeView = ({ src, title }) => (
    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
        <iframe
            src={src}
            title={title}
            width="100%"
            height="400px"
            style={{ border: '1px solid #ddd', borderRadius: '4px' }}
            loading="lazy"
            allowFullScreen
        ></iframe>
    </div>
);

const LinkView = ({ href }) => (
    <Typography variant="body1" style={{ marginTop: '10px' }}>
        <a href={href} target="_blank" rel="noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
            {href}
        </a>
    </Typography>
);

export default Unseen;
