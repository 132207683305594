import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Button, Typography, Grid, Paper } from '@mui/material';
import { isMobile, renderTextWithBreakLines, shuffleArray } from '../../utils';
import QuestionTypeManager from './QuestionTypeManager';
import { useLanguage } from '../../contexts/languageContext';
import SubmissionDialog from './SubmissionDialog';
import { questionsType } from '../../utils/examOptions';
import Unseen from '../../components/Unseen';
import MathInput from 'react-math-keyboard';
import { latexDelimiter } from '../../components/CustomLatex';
import { inputTypes } from '../../utils/configs';
import ExamHeader from './ExamHeader';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { getAudioForQuestion } from '../../requests/exam';

/**
 * @param {{ examJson: Object, onFinish: Function, onLeavingExam: Function }} param0
 */
const Exam = ({ examJson, onFinish, onLeavingExam }) => {
    const [answers, setAnswers] = useState(() => getInitialAnswers(examJson?._id));
    const [displaySubmitError, setDisplaySubmitError] = useState(false);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    // const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const { languageData, isRTL } = useLanguage();
    const firstUnansweredQuestionRef = useRef(null);
    const mathFields = useRef({});
    const leftExam = useRef(false);
    const [examState, setExamState] = useState(examJson);

    useEffect(() => {
        setExamState(examJson);
    }, [examJson]);

    useEffect(() => {
        const saveAnswers = () => {
            const expirationTime = Date.now() + 60 * 60 * 1000; // 1 hour expiration
            localStorage.setItem('examAnswers', JSON.stringify({ answers, expirationTime, examId: examJson._id }));
        };
        if (answers && Object.keys(answers).length > 0) saveAnswers();
    }, [answers]);

    // ########################################## Event functions ##########################################
    const handleBeforeUnload = useCallback((event) => {
        event.preventDefault();
        event.returnValue = '';
    }, []);

    const handleVisibilityChange = useCallback(() => {
        if (document.hidden && !leftExam.current) {
            leftExam.current = true;
            handleFinish(true);
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (!leftExam.current) {
            onLeavingExam(answers); // Show countdown dialog only if not yet submitted
        }
    }, [answers]);

    const handleBlur = useCallback(() => {
        onLeavingExam(answers);
    }, [answers])

    const handleResize = isMobile ? () => { } : useCallback(() => {
        const minWidth = 800; // Define minimum width for full quiz visibility
        const minHeight = 600; // Define minimum height for full quiz visibility

        if (window.innerWidth < minWidth || window.innerHeight < minHeight) {
            onLeavingExam(answers)
        }
    }, [answers])

    const handleCopyPaste = e => {
        e.preventDefault();
    }

    useEffect(() => {
        if (examJson?.settings?.is_supervised) {
            enterFullScreen();

            window.addEventListener('beforeunload', handleBeforeUnload);
            document.addEventListener('visibilitychange', handleVisibilityChange);
            window.addEventListener('blur', handleBlur);
            if (!isMobile) window.addEventListener('resize', handleResize);
            document.addEventListener('mouseleave', handleMouseLeave);
            // Add listeners to prevent copy, cut, and paste
            document.addEventListener('copy', handleCopyPaste);
            document.addEventListener('cut', handleCopyPaste);
            document.addEventListener('paste', handleCopyPaste);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                window.removeEventListener('blur', handleBlur);
                if (!isMobile) window.removeEventListener('resize', handleResize);
                document.removeEventListener('mouseleave', handleMouseLeave);
                document.removeEventListener('copy', handleCopyPaste);
                document.removeEventListener('cut', handleCopyPaste);
                document.removeEventListener('paste', handleCopyPaste);
            };
        }
    }, [examJson, languageData, answers]);

    // ########################################## End Event functions ##########################################

    const handleAnswer = (questionId, answer) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: answer
        }));
    };

    const handleMathAnswer = (questionId) => {
        if (!mathFields.current[questionId].latex()) return;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: (prevAnswers[questionId] ?? '') + `${latexDelimiter}${mathFields.current[questionId].latex()}${latexDelimiter}`
        }));
        mathFields.current[questionId].latex('');
    };

    const handleFinish = (didLeaveTab = false) => {
        if (didLeaveTab) {
            let restoredAnswers = { ...answers };
            if (!restoredAnswers || Object.keys(restoredAnswers).length === 0) restoredAnswers = getInitialAnswers(examJson?._id);

            onFinish(restoredAnswers, examJson.questions, { leftExam: true }).then(() => {
                localStorage.removeItem('examAnswers'); // Clear data after submission
            }).catch(e => console.error(e));
            return;
        }

        leftExam.current = true;
        onFinish(answers, examJson.questions).then(() => {
            localStorage.removeItem('examAnswers'); // Clear data after submission
        }).catch(e => console.error(e));
    };

    // const validateAnswers = () => {
    //     const submittedQuestions = Object.keys(answers).filter(answerId => {
    //         const question = examJson.questions.find((question) => question._id === answerId);
    //         if (question) {
    //             return question.type === questionsType.nested
    //                 ? answers[answerId].length === question.nested_questions.length && answers[answerId].every((ans) => ans.answer)
    //                 : !!question;
    //         }
    //         return false;
    //     });
    //     return submittedQuestions.length === examJson.questions.length;
    // };

    // const handleReadQuestion = (question) => {
    //     if (isAudioPlaying) return; // Prevent playing multiple audios at the same time

    //     if (question.audio_data?.url) {
    //         setIsAudioPlaying(true);
    //         const audio = new Audio(question.audio_data.url);
    //         playAudio(audio);
    //     } else {
    //         // Fetch and cache audio
    //         setIsAudioPlaying(true);
    //         getAudioForQuestion(question._id, examState._id)
    //             .then((response) => {
    //                 const audio = new Audio(response.audio_data.url);
    //                 playAudio(audio);

    //                 // Update examState to include audio URL
    //                 setExamState((prev) => {
    //                     const updatedQuestions = prev.questions.map((q) =>
    //                         q._id === question._id
    //                             ? { ...q, audio_data: response.audio_data }
    //                             : q
    //                     );
    //                     return { ...prev, questions: updatedQuestions };
    //                 });
    //             })
    //             .catch((e) => {
    //                 console.error('Error fetching audio:', e);
    //                 setIsAudioPlaying(false);
    //             });
    //     }
    // };

    // const playAudio = (audio) => {
    //     audio.play()
    //         .then(() => {
    //             audio.onended = () => {
    //                 setIsAudioPlaying(false); // Set to false when the audio finishes
    //             };
    //         })
    //         .catch((e) => {
    //             console.error('Error playing audio:', e);
    //             setIsAudioPlaying(false);
    //         });
    // };

    const closeSubmitDialog = () => setShowSubmitDialog(false);
    const handleSubmitDialog = () => {
        setShowSubmitDialog(false);
        handleFinish();
    };

    const shuffledQuestions = useMemo(() =>
        examState?.settings?.shuffle_questions ? shuffleArray(examState.questions) : examState.questions,
        [examState.questions]
    );

    if (!examJson) {
        return <Typography variant="body1">Error fetching exam. Please refresh the page or try again later.</Typography>;
    }

    // CSS styles as a constant at the top of the component
    const styles = {
        mathInputContainer: {
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '10px',
            margin: '20px auto',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            textAlign: 'left',
        },
        mathInput: {
            backgroundColor: '#f9f9f9',
            border: '1px solid #ddd',
            padding: '3px',
            borderRadius: '5px',
            fontSize: '16px',
            width: '100%',
            boxSizing: 'border-box',
        },
        clickableIcon: {
            cursor: 'pointer',
        },
    };

    return (
        <div dir={isRTL ? 'rtl' : 'ltr'}>
            <ExamHeader questions={shuffledQuestions} answers={answers} />
            <Box p={isMobile ? '0px 8px 80px 8px' : '40px'} sx={{ margin: 'auto', maxWidth: '75rem' }}>
                {examJson.instructions && (
                    <Grid mb={"20px"}>
                        <Unseen text={examJson.instructions} />
                    </Grid>
                )}
                {!examJson?.settings?.is_supervised && examJson?.settings?.show_url == true && examJson?.parameters?.source === inputTypes.url && examJson?.parameters?.prompt &&
                    <Grid mb={"20px"}>
                        <Unseen link={examJson.parameters.prompt} />
                    </Grid>
                }
                {examJson.text && (
                    <Grid mb={"20px"}>
                        <Unseen text={examJson.text} />
                    </Grid>
                )}
                {shuffledQuestions.map((question, index) => (
                    <Paper sx={{ p: 3, mt: 3 }} key={index}>
                        <Grid key={question._id} container spacing={1} justifyContent="center">
                            <Grid item xs={12} id={question._id}>
                                <Typography variant="h5" gutterBottom>
                                    {`${languageData?.quiz?.question_label} ${index + 1}`}
                                </Typography>
                            </Grid>
                            {question.image?.url && (
                                <Grid item xs={12}>
                                    <img src={question.image.url} alt="" style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {/* {examJson?.settings?.recitation && (<VolumeUpIcon onClick={() => handleReadQuestion(question)} style={{
                                    ...styles.clickableIcon,
                                    pointerEvents: isAudioPlaying ? 'none' : 'auto',  // Disable pointer events when audio is playing
                                    opacity: isAudioPlaying ? 0.5 : 1, // Make it look visually disabled when playing
                                }}
                                    disabled={isAudioPlaying}
                                />)} */}
                                <QuestionTypeManager
                                    type={question.type}
                                    allQuestionsProps={{
                                        questionId: question._id,
                                        title: question.question,
                                        onAnswer: handleAnswer,
                                        savedAnswer: answers[question._id] || "",
                                    }}
                                    openQuestionProps={{}}
                                    nestedQuestionsProps={{ nestedQuestions: question.nested_questions, parentIndex: index }}
                                    closeQuestionProps={{
                                        options: question?.options,
                                        correctAnswer: question?.correctAnswer,
                                    }}
                                    graphQuestionProps={{
                                        functions: question.functions,
                                    }}
                                />
                                {((question.type === questionsType.open ||
                                    question.type === questionsType.graph
                                ) && examJson?.settings?.math_keyboard)
                                    && (
                                        <>
                                            <div dir="ltr" id="my-math-input" style={styles.mathInputContainer}>
                                                <Typography variant='body1'>Math Keyboard</Typography>
                                                <MathInput
                                                    setMathfieldRef={(mathfield) => mathFields.current[question._id] = mathfield}
                                                    style={styles.mathInput}
                                                />
                                                <Button variant='outlined' onClick={e => handleMathAnswer(question._id)}>Submit</Button>
                                            </div>
                                            <div style={{ padding: '10px' }}>
                                                <Typography variant='body1'>{renderTextWithBreakLines(answers[question._id])}</Typography>
                                            </div>
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
                <Box mt={2}>
                    <Button onClick={() => setShowSubmitDialog(true)} variant="contained" color="primary" fullWidth>
                        {languageData?.quiz?.exam.finish_label}
                    </Button>
                </Box>
            </Box>
            <SubmissionDialog
                open={showSubmitDialog}
                closeDialog={closeSubmitDialog}
                submitExam={handleSubmitDialog}
            />
        </div>
    );
};

export default Exam;

function getInitialAnswers(examId) {
    const storedAnswers = localStorage.getItem('examAnswers');
    if (storedAnswers) {
        const parsedAnswers = JSON.parse(storedAnswers);
        const expirationTime = parsedAnswers.expirationTime;
        if (expirationTime && Date.now() > expirationTime || parsedAnswers.examId !== examId) {
            localStorage.removeItem('examAnswers');
            return {};
        }
        return parsedAnswers.answers;
    }
    return {};
}
function enterFullScreen() {
    try {
        const elem = document.documentElement; // Target the whole page or a specific element
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
    } catch (e) {
        console.error(e);
    }
}
