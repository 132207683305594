// React
import React, { useEffect, useState } from 'react';
// Components
import { Button, CircularProgress, Typography } from '@mui/material';
import CardList from '../components/CardList'
import Header from '../components/Header';
// Hooks
import useSnackBar from '../hooks/useSnackBar';
import useHistory from '../hooks/useHistory';
import { colors, paddingPageStyle } from '../utils/customTheme';
import { Add as AddIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
// import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import GoogleAds from '../components/GoogleAds';
import { useUser } from '../hooks/useUser';
import Filters from '../components/Filters/Filters';
import { applyExamFilters } from '../components/Filters/filtersUtils';

const HistoryPage = () => {
    const { isPremium } = useUser()
    const { history, deleteFromHistory } = useHistory();
    const { languageData, isRTL } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const [historyData, setHistoryData] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (history?.length > 0) {
            if (!initialized) {
                setLoading(true);
                setHistoryData(history);
                setInitialized(true);
                setLoading(false);
            } else {
                setHistoryData(history);
            }
        } else {
            setInitialized(false);
            setLoading(false);
        }
    }, [history, initialized]);

    const deleteExam = (examId) => {
        openSnackBar('DELETE_EXAM');
        deleteFromHistory(examId).then(() => {
            openSnackBar('EXAM_DELETED');
        }).catch(err => {
            openSnackBar('ERROR');
        });
    };

    const handleFilterChange = (filters) => {
        const filteredHistory = applyExamFilters(history, filters);
        setHistoryData(filteredHistory);
    };

    return (
        <>
            <Helmet>
                <title>StudyWise | History</title>
                <meta name="description" content="Review your past exams and track your progress with our AI-powered exam generator. Easily revisit and analyze previous exams to enhance future learning outcomes." />
            </Helmet>
            <Header />
            {!isPremium() && <GoogleAds />}
            <div style={{ ...paddingPageStyle, padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h5' sx={{ fontWeight: 700, fontSize: '2rem', margin: '10px 0 10px 0', direction: isRTL ? 'rtl' : 'ltr' }}>
                    {languageData?.history?.title?.replace('{amount}', historyData?.length ?? 0) ?? ''}
                </Typography>

                <Filters onFilterChange={handleFilterChange} />

                {/* Exams list: */}
                {historyData?.length > 0 ? (
                    <CardList exams={historyData} deleteExam={deleteExam} />
                ) : (loading ?
                    <CircularProgress disableShrink size={'4rem'} sx={{ color: colors.main, marginTop: '3rem' }} />
                    :
                    <>
                        <p>{languageData?.history?.no_exams || "No exams available"}</p>
                        <Button
                            variant='contained'
                            sx={{ borderRadius: '50%', width: '100px', height: '100px', marginTop: '5rem' }}
                            onClick={() => window.location.href = "/exam"}
                        >
                            <AddIcon sx={{ fontSize: '3rem' }} />
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default HistoryPage;
