import api from "./api";


export const requestResendVlaidationEmail = async (email) => {
    try {
        const response = await api.post('/auth/resend-verification', { email });
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}
export const requestVerifyEmail = async (token, email) => {
    try {
        const response = await api.post('/auth/verify', { token, email });
        return response.status;
    } catch (error) {
        console.error(error);
        return null;
    }
}

