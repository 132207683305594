export const validateEmail = (input) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(input);
};

export const validatePassword = (input) => {
    const regex = /^.{6,}$/;
    return regex.test(input);
};

export const sanitizeText = (text) => {
    const dangerousChars = /[<>`''\\{}]/g;

    // Replace dangerous characters with an empty string or escape them
    return text.replace(dangerousChars, '');
};

export function escapeRegex(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
}

export function escapeCsvValue(value) {
    if (typeof value === "string") {
        return `"${value.replace(/"/g, '""')}"`; // Wrap in quotes and escape quotes
    }
    return value;
};
