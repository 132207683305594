import { createContext, useContext, useState } from 'react';
import { getHub } from '../requests/hub';
import { useSubjects } from '../contexts/SubjectsContext';
import { links } from '../utils';

const hubContext = createContext();

export const useHub = () => {
    const context = useContext(hubContext);
    if (!context) {
        throw new Error('useHub must be used within a HubProvider');
    }
    return context;
};


export const HubProvider = ({ children }) => {
    /** @type {[{ _id: string, difficulty: string, exam_id: string, language: string, subject: string, title: string, upvotes_count: number }[], Function]} */
    const [hub, setHub] = useState(null)
    const { subjects } = useSubjects();
    const OtherSubject = { labels: { en: "Other", he: "אחר", ar: "آخر" }, cover: links.LOGO }

    const fetchHub = async () => {
        if (hub) return Promise.resolve(hub);
        const subjectsMap = {}
        return getHub().then(res => {
            const updatedExams = res.map(exam => {
                if (exam?.subject?.length) {
                    const examSubjectId = exam?.subject[0];
                    const subjectObj = subjectsMap[examSubjectId] ?? subjects.find(subject => subject._id === examSubjectId);
                    if (subjectObj) {
                        subjectsMap[examSubjectId] = subjectObj;
                        return { ...exam, subject: subjectObj };
                    } else
                        return { ...exam, subject: OtherSubject };
                } else
                    return { ...exam, subject: OtherSubject };
            });
            setHub(updatedExams);
            return updatedExams;
        });
    };


    return (
        <hubContext.Provider value={{ hub, fetchHub }}>
            {children}
        </hubContext.Provider>
    );
}
