export const SnackbarTypes = {
    SUCCESS: { msg: "Exam is ready!", hebrew_msg: "המבחן מוכן", arabic_msg: "الامتحان جاهز!", severity: 'success' },
    ERROR: { msg: "Something went wrong, please try again or refresh", hebrew_msg: "משהו השתבש, אנא נסה שוב או רענן את העמוד", arabic_msg: "حدث خطأ ما، يرجى المحاولة مرة أخرى أو التحديث", severity: 'error' },
    ERROR_CONTACT: { msg: "Something went wrong, please try again or contact support", hebrew_msg: "משהו השתבש, אנא נסה שוב או צרו קשר לתמיכה", arabic_msg: "حدث خطأ ما، يرجى المحاولة مرة أخرى أو الاتصال بالدعم", severity: 'error' },
    FORM_INVALID: { msg: "Form Submitted is invalid", hebrew_msg: "אחד השדות שגוי או חסר", arabic_msg: "النموذج المقدم غير صالح", severity: 'error' },
    WAITING: { msg: "Generation may take up to 5 minutes", hebrew_msg: "היצירה עשויה לקחת עד 5 דקות", arabic_msg: "قد تستغرق العملية حتى 5 دقائق", severity: 'info' },
    WAITING2: { msg: "Creating structure...", hebrew_msg: "יוצר את מבנה המבחן", arabic_msg: "إنشاء الهيكل...", severity: 'info' },
    WAITING3: { msg: "Phrasing questions...", hebrew_msg: "מנסח שאלות", arabic_msg: "صياغة الأسئلة...", severity: 'info' },
    WAITING4: { msg: "Almost done...", hebrew_msg: "כמעט וסיימנו, היצירה בשלבי סיום", arabic_msg: "قاربنا على الانتهاء...", severity: 'info' },
    HISTORY: { msg: 'Exam pushed to history', hebrew_msg: 'המבחן נכנס להיסטוריה', arabic_msg: 'تم نقل الامتحان إلى التاريخ', severity: 'success' },
    LOGIN_FAILED: { msg: 'Login Failed.', hebrew_msg: 'ההתחברות נכשלה', arabic_msg: 'فشل تسجيل الدخول.', severity: 'error' },
    LOGGED_IN: { msg: 'You Are Logged In', hebrew_msg: 'התחברת בהצלחה', arabic_msg: 'تم تسجيل الدخول بنجاح', severity: 'success' },
    INVALID_CREDENTIALS: { msg: 'Please enter valid email and password', hebrew_msg: 'נא להזין אימייל וסיסמה תקניים', arabic_msg: 'يرجى إدخال بريد إلكتروني وكلمة مرور صالحين', severity: 'error' },
    VERIFY_EMAIL_BEFORE_LOGIN: { msg: 'You must verify your email before logging in. Check your email for the verification link.', hebrew_msg: 'נא לאמת את האימייל לפני התחברות. יש לבדוק את האימייל לקישור האימות.', arabic_msg: 'يجب عليك التحقق من بريدك الإلكتروني قبل تسجيل الدخول. تحقق من بريدك الإلكتروني للحصول على رابط التحقق.', severity: 'warning' },
    DELETE_EXAM: { msg: 'Deleting...', hebrew_msg: 'מוחק', arabic_msg: 'جارٍ الحذف...', severity: 'info' },
    EXAM_DELETED: { msg: 'Deleted Successfully', hebrew_msg: 'נמחק בהצלחה', arabic_msg: 'تم الحذف بنجاح', severity: 'success' },
    LIMIT: { msg: 'Oops, text is too long', hebrew_msg: 'הטקסט ארוך מדי', arabic_msg: 'عذرًا، النص طويل جدًا', severity: 'error' },
    FILE_LIMIT: { msg: 'Files are too large. Last file will be truncated', hebrew_msg: 'התוכן ארוך ולכן יקוצר לגבול האותיות', arabic_msg: 'الملفات كبيرة جدًا. سيتم اقتطاع الملف الأخير', severity: 'warning', duration: 8000 },
    NO_TEXT: { msg: 'Please insert text or file to create an exam', hebrew_msg: 'נא להכניס טקסט או קובץ כדי ליצור מבחן', arabic_msg: 'يرجى إدخال نص أو ملف لإنشاء امتحان', severity: 'error' },
    TRANSCRIPT: { msg: 'YouTube transcript is too long', hebrew_msg: 'הטקסט מהסרטון יוטיוב ארוך מדי', arabic_msg: 'نص يوتيوب طويل جدًا', severity: 'error' },
    INVALID_YOUTUBE_ID: { msg: 'YouTube link is invalid', hebrew_msg: 'הקישור לא תקין', arabic_msg: 'رابط يوتيوب غير صالح', severity: 'error' },
    VALID_YOUTUBE_ID: { msg: 'YouTube link is Valid', hebrew_msg: 'הקישור תקין, נסו ליצור מבחן', arabic_msg: 'رابط يوتيوب صالح', severity: 'success' },
    UNAUTHORIZED_SIGNUP: { msg: 'Oops! Organization sigup has failed. Please contact support.', hebrew_msg: 'אופס! נראה שההרשמה לארגון נכשלה. צור קשר עם התמיכה לקבלת עזרה.', arabic_msg: 'عذرًا! فشل تسجيل المنظمة. يرجى الاتصال بالدعم.', severity: 'error' },
    ORG_CREATED_SUCCESS: { msg: 'Organization Created Successfully!', hebrew_msg: 'הארגון נוצר בהצלחה!', arabic_msg: 'تم إنشاء المنظمة بنجاح!', severity: 'success' },
    ORG_CREATED_FAILED: { msg: 'Creating Organization Failed.', hebrew_msg: 'יצירת הארגון נכשלה', arabic_msg: 'فشل إنشاء المنظمة.', severity: 'error' },
    ORG_UPDATED_SUCCESS: { msg: 'Organization Updated Successfully!', hebrew_msg: 'הארגון עודכן בהצלחה!', arabic_msg: 'تم تحديث المنظمة بنجاح!', severity: 'success' },
    ORG_UPDATED_FAILED: { msg: 'Updating Organization Failed', hebrew_msg: 'עדכון הארגון נכשל', arabic_msg: 'فشل تحديث المنظمة', severity: 'error' },
    NO_SUBTITLES: { msg: 'YouTube video must have subtitles', hebrew_msg: 'לסרטון אין כתוביות, לא ניתן לקרוא מידע', arabic_msg: 'يجب أن يحتوي فيديو يوتيوب على ترجمات', severity: 'error' },
    CONFLICT: { msg: 'Email already registered. If this email is yours try to login. Otherwise, contact support for further assistance', hebrew_msg: 'האימייל בשימוש, אם המייל שלך - נסו להתחבר. אחרת צרו קשר לתמיכה', arabic_msg: 'البريد الإلكتروني مسجل بالفعل. إذا كان هذا البريد الإلكتروني لك، حاول تسجيل الدخول. خلاف ذلك، اتصل بالدعم للحصول على مزيد من المساعدة', severity: 'info', duration: 10000 },
    RESET_PASSWORD: { msg: 'Reset code will be sent to your email, if the email exists', hebrew_msg: 'קוד שחזור יישלח למייל, אם המייל קיים במערכת', arabic_msg: 'سيتم إرسال رمز إعادة التعيين إلى بريدك الإلكتروني، إذا كان البريد الإلكتروني موجودًا', severity: 'info' },
    INVALID_RESET_CODE: { msg: 'The code you entered might be invalid', hebrew_msg: 'ייתכן והקוד שהוכנס אינו תקין', arabic_msg: 'قد يكون الرمز الذي أدخلته غير صالح', severity: 'error' },
    RESET_PASSWORD_SUCCEEDED: { msg: 'Password was reset successfully, you are being redirected...', hebrew_msg: 'איפוס סיסמא עבר בהצלחה, את/ה מועבר להתחברות', arabic_msg: 'تم إعادة تعيين كلمة المرور بنجاح، يتم إعادة توجيهك...', severity: 'success' },
    SIGNUP_FIRST: { msg: 'Saving Failed. Please Sign-Up first...', hebrew_msg: 'נא להירשם קודם, השמירה נכשלה', arabic_msg: 'فشل الحفظ. يرجى التسجيل أولاً...', severity: 'error' },
    SAVE_SUCCESS: { msg: 'Successful Saving. Welcome Aboard', hebrew_msg: 'השמירה הצליחה! ברוך הבא', arabic_msg: 'تم الحفظ بنجاح. مرحبًا بك', severity: 'success' },
    SUBMIT_SUCCESS: { msg: 'Successful Submission', hebrew_msg: 'השליחה הצליחה', arabic_msg: 'تم الإرسال بنجاح', severity: 'success' },
    SUBMIT_FAILED: { msg: 'Submission Failed', hebrew_msg: 'השליחה נכשלה', arabic_msg: 'فشل الإرسال', severity: 'error' },
    SAVED_FOR_LATER_SUCCESS: { msg: 'Saved Successfuly', hebrew_msg: 'נשמר בהצלחה', arabic_msg: 'تم الحفظ بنجاح', severity: 'success' },
    SAVED_FOR_LATER_FAILED: { msg: 'Saving for later failed', hebrew_msg: 'השמירה נכשלה', arabic_msg: 'فشل الحفظ لوقت لاحق', severity: 'error' },
    NO_EXAM_ID: { msg: 'Invalid Exam ID. Make sure link is correct', hebrew_msg: 'מזהה מבחן לא חוקי. ודאו כי הקישור תקין', arabic_msg: 'معرف الامتحان غير صالح. تأكد من أن الرابط صحيح', severity: 'error', duration: 12000 },
    GRADE_ERROR: { msg: 'One of the grades you gave is invalid', hebrew_msg: 'אחד הציונים שנתת לא תקין', arabic_msg: 'أحد الدرجات التي أعطيتها غير صالح', severity: 'error' },
    COPIED_LINK: { msg: 'Link Has Been Copied', hebrew_msg: 'הקישור הועתק בהצלחה', arabic_msg: 'تم نسخ الرابط بنجاح', severity: 'success' },
    VALIDATION_FAILED: { msg: 'Validation Failed', hebrew_msg: 'האימות נכשל', arabic_msg: 'فشل التحقق', severity: 'error' },
    RESEND_FAILD: { msg: 'Resend Failed', hebrew_msg: 'השליחה נכשלה', arabic_msg: 'فشل إعادة الإرسال', severity: 'error' },
    APPROVE_EXAM_TO_HUB: { msg: 'Your Exam has been uploaded to the Hub!', hebrew_msg: 'המבחן הועלה למאגר בהצלחה!', arabic_msg: 'تم تحميل امتحانك إلى المركز بنجاح!', severity: 'success' },
    AFTER_REMIX: { msg: `Exam remixed successfully!\n You can change the exam's parameters \n When you finish click on Generate Exam`, hebrew_msg: `נתוני המבחן הוזנו בהצלחה! \n שנו את הנתונים במידת הצורך. \n בסיום, לחצו על יצירת מבחן.`, arabic_msg: `تم إعادة مزج الامتحان بنجاح!\n يمكنك تغيير معلمات الامتحان \n عند الانتهاء، انقر على إنشاء امتحان`, severity: 'success' },
    AI_REPORT_FAILED: { msg: `Creating AI report has failed!`, hebrew_msg: `יצירת דוח התלמיד נכשלה!`, arabic_msg: `فشل إنشاء تقرير الذكاء الاصطناعي!`, severity: 'error' },
    FETCH_FAILED: { msg: 'Fetching Failed', hebrew_msg: 'הבאת המידע נכשלה', arabic_msg: 'فشل الجلب', severity: 'error' },
    FETCHING: { msg: `Fetching...`, hebrew_msg: `טוענים`, arabic_msg: `جارٍ الجلب...`, severity: 'info', duration: 3000 },
    FETCH_SUCCEEDED: { msg: 'Fetched Successfully', hebrew_msg: 'הבאת המידע הצליחה', arabic_msg: 'تم الجلب بنجاح', severity: 'success' },
    READING_FILE: { msg: 'Reading file...', hebrew_msg: 'קורא את הקובץ', arabic_msg: 'جارٍ قراءة الملف...', severity: 'info' },
    CHECK_FILE: { msg: 'Check file - no images or scanned', hebrew_msg: 'ודאו שאין תמונות או מסמכים סרוקים בקובץ', arabic_msg: 'تحقق من الملف - لا توجد صور أو مسح ضوئي', severity: 'error' },
    FILE_READY: { msg: 'File is ready', hebrew_msg: 'הקובץ מוכן', arabic_msg: 'الملف جاهز', severity: 'success' },
    ONBOARDING_DATA_SAVED_SUCCESS: { msg: 'Onboarding data saved successfully', hebrew_msg: 'נתוני ההתחברות נשמרו בהצלחה', arabic_msg: 'تم حفظ بيانات الإعداد بنجاح', severity: 'success' },
    FILE_TYPE_NOT_SUPPORTED: { msg: 'File type is not supported, Images only', hebrew_msg: 'סוג הקובץ לא נתמך, נא להעלות תמונות בלבד', arabic_msg: 'نوع الملف غير مدعوم، الصور فقط', severity: 'error' },
    RATE_LIMIT_EXCEEDED: { msg: 'You’ve reached the usage limit. Please try again later', hebrew_msg: 'הגעת למגבלת השימוש, נסה שוב מאוחר יותר', arabic_msg: 'لقد وصلت إلى حد الاستخدام. يرجى المحاولة مرة أخرى لاحقًا', severity: 'error' },
    RESPONSES_NOT_ALLOWED: { msg: 'Responses are not allowed for this exam at the moment', hebrew_msg: 'מענה לא מותר כרגע', arabic_msg: 'الردود غير مسموح بها لهذا الامتحان في الوقت الحالي', severity: 'error' },
};

Object.keys(SnackbarTypes).forEach(key => {
    SnackbarTypes[key].field = key
})
