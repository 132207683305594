import React, { useEffect, useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, Typography, Button } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { useSubjects } from '../../contexts/SubjectsContext';
import CustomSelect from '../CustomSelect';

const HubApproveDialog = ({ open, onClose, onApprove, subject }) => {
    const { languageData, isRTL, language } = useLanguage();
    const { subjects } = useSubjects();
    const [selectedSubject, setSelectedSubject] = useState(subject);

    useEffect(() => {
        setSelectedSubject(subject);
    }, [subject])

    const handleApprove = () => {
        if (selectedSubject) {
            onApprove(selectedSubject);
        }
    };
    const options = subjects?.map(subject => ({ value: subject._id, label: subject.labels[language] ?? subject.labels.en }))?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];
    return (
        <Dialog open={open} onClose={onClose} dir={isRTL ? 'rtl' : 'ltr'}>
            <DialogTitle>{languageData?.hub.dialog.title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomSelect
                            value={selectedSubject}
                            onChangeHandler={(e) => setSelectedSubject(e.target.value)}
                            firstDisabledItem={languageData?.hub.dialog.select_subject}
                            id={languageData?.hub.dialog.select_subject}
                            options={options}
                            useFirstOptionIfNoValue={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {languageData?.hub.dialog.subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {languageData?.hub.dialog.cancel}
                </Button>
                <Button onClick={handleApprove} variant='contained' color="primary" disabled={!selectedSubject}>
                    {languageData?.hub.dialog.approve}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HubApproveDialog;
