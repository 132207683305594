export const applyExamFilters = (exams, filters) => {
    return exams.filter(exam => {
        return (!filters.search || exam.title.toLowerCase().includes(filters.search.toLowerCase())) &&
            (!filters.difficulty || exam.parameters?.difficulty === filters.difficulty) &&
            (!filters.language || exam.parameters.language === filters.language) &&
            (!filters.showExamsWithResponses || exam.responses_count > 0);
    });
};

export const applyHubFilters = (exams, filters, language) => {
    return exams.filter(exam => {
        return (!filters.search || exam.title.toLowerCase().includes(filters.search.toLowerCase())) &&
            (!filters.difficulty || exam.difficulty === filters.difficulty) &&
            (!filters.language || exam.language === filters.language) &&
            (filters.subject.labels[language] === exam.subject.labels[language]);
    });
};